import React from 'react';
import ReactDOM from 'react-dom';
import { CallbackProvider } from '../components/callback/callback.context';
import { Callback } from '../components/callback';

import SmoothScroll from 'smooth-scroll';

const headerEl = document.querySelector('.header');
const headerButton = document.querySelector('.header__button');

const initMobileMenu = () => {
  headerButton?.addEventListener('click', (event) => {
    event.stopPropagation();
    headerEl?.classList.toggle('header--open');
  });

  const headerNav = document.querySelector('.header__nav');
  document.addEventListener('click', (event) => {
    if (
      headerEl?.classList.contains('header--open') &&
      event.target instanceof HTMLElement &&
      !headerNav?.contains(event.target)
    ) {
      headerEl.classList.remove('header--open');
    }
  });
};

const initNavButtons = () => {
  const buttons =
    document.querySelectorAll<HTMLAnchorElement>('.header__nav a');
  buttons.forEach((item) =>
    item.addEventListener('click', () => {
      headerEl!.classList.remove('header--open');
    })
  );
};

const initStickyHeader = () => {
  window.addEventListener('scroll', () => {
    const header = document.querySelector<HTMLHeadingElement>('.header');
    if (header) {
      if (window.pageYOffset > 30) {
        header.classList.add('header--sticky');
      } else {
        header.classList.remove('header--sticky');
      }
    }
  });
};

const initSmoothScroll = () => {
  new SmoothScroll('a[href*="#"]');
};

const initCallback = () => {
  const button = document.querySelector('.header__callback');

  button?.addEventListener('click', () => {
    ReactDOM.render(
      <React.StrictMode>
        <CallbackProvider>
          <Callback />
        </CallbackProvider>
      </React.StrictMode>,
      document.getElementById('root')
    );
  });
};

const header = {
  init: () => {
    initMobileMenu();
    initNavButtons();
    initStickyHeader();
    initSmoothScroll();
    initCallback();
  },
};

export default header;

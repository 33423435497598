import { FC, useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Modal } from '../_common/modal';
import { Textual } from '../_common/textual';
import { Thanks } from '../_common/thanks';
import { CalculationBody } from './calculation-body';
import { CalculationContext } from './calculation.context';
import { Room } from './calculation.types';

interface Props {
  room: Room;
}

export const Calculation: FC<Props> = ({ room }) => {
  const { state, dispatch } = useContext(CalculationContext);

  useEffect(() => {
    if (!state.isMounted) {
      ReactDOM.unmountComponentAtNode(document.getElementById('root')!);
    }
  }, [state.isMounted]);

  const checkUnmount = () => {
    if (!state.modalIsOpen && !state.thanksIsOpen && !state.policyIsOpen) {
      window.history.replaceState(null, '', '/');
      dispatch({ type: 'unmount' });
    }
  };

  return (
    <>
      <Modal
        isOpen={state.modalIsOpen}
        onOverlayClick={() => dispatch({ type: 'closeModal' })}
        onFinishAnimation={() => checkUnmount()}
      >
        <CalculationBody
          room={room}
          onClose={(isNeedThanks) => {
            dispatch({ type: 'closeModal' });
            if (isNeedThanks) {
              dispatch({ type: 'openThanks' });
            }
          }}
        />
      </Modal>
      <Modal
        isOpen={state.thanksIsOpen}
        onOverlayClick={() => dispatch({ type: 'closeThanks' })}
        onFinishAnimation={() => checkUnmount()}
      >
        <Thanks onClose={() => dispatch({ type: 'closeThanks' })} />
      </Modal>
      <Modal
        isOpen={state.policyIsOpen}
        onOverlayClick={() => dispatch({ type: 'closePolicy' })}
        onFinishAnimation={() => checkUnmount()}
        withoutOverlay
      >
        <Textual
          type="policy"
          onClose={() => dispatch({ type: 'closePolicy' })}
        />
      </Modal>
    </>
  );
};

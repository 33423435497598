import { createContext, Dispatch, FC, useReducer } from 'react';
import { calculationReducer } from './calculation.reducer';
import { CalculationState, CalculationAction } from './calculation.types';

const initialState: CalculationState = {
  step: 1,
  arrival: undefined,
  departure: undefined,
  adults: 2,
  babies: [],
  name: '',
  phone: '',
  policyIsChecked: false,
  modalIsOpen: true,
  arrivalIsOpen: false,
  departureIsOpen: false,
  babiesIsOpen: false,
  thanksIsOpen: false,
  policyIsOpen: false,
  isMounted: true,
};

export const CalculationContext = createContext<{
  state: CalculationState;
  dispatch: Dispatch<CalculationAction>;
}>({ state: initialState, dispatch: () => null });

export const CalculationProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(calculationReducer, initialState);

  return (
    <CalculationContext.Provider value={{ state, dispatch }}>
      {children}
    </CalculationContext.Provider>
  );
};

import { FC, useState } from 'react';
import { useCookie } from '../../hooks/useCookie';
import { Modal } from '../_common/modal';
import { Textual } from '../_common/textual';
import { CookieBody } from './cookie-body/cookie-body';

export const Cookie: FC = () => {
  const [isShow, setIsShow] = useState(true);
  const [cookie, updateCookie] = useCookie('cookie_notification');

  const [isShowPolicy, setIsShowPolicy] = useState(false);

  return !cookie && isShow ? (
    <>
      <CookieBody
        onClose={() => setIsShow(false)}
        onPolicyClick={() => setIsShowPolicy(true)}
        onConfirm={() => updateCookie('confirmed', 365)}
      />
      <Modal
        isOpen={isShowPolicy}
        onOverlayClick={() => setIsShowPolicy(false)}
      >
        <Textual type="policy" onClose={() => setIsShowPolicy(false)} />
      </Modal>
    </>
  ) : null;
};

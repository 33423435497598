import { forwardRef } from 'react';
import cn from 'classnames';
import styles from './date-picker.module.scss';

interface Props {
  name: string;
  onClick: () => void;
  value?: Date;
  moduleClass?: string;
}

export const DatePicker = forwardRef<HTMLDivElement, Props>(
  ({ name, onClick, value, moduleClass }, ref) => {
    const moduleStyles = cn(styles.module, moduleClass);

    return (
      <div className={moduleStyles} ref={ref} onClick={onClick}>
        <span className={styles.name}>{name}</span>
        <span className={styles.value}>
          {value && value.toLocaleDateString()}
        </span>
        <img
          className={styles.icon}
          src="/images/date-icon.svg"
          alt="date-icon"
        />
      </div>
    );
  }
);

import { FC, MouseEvent, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import styles from './modal.module.scss';

const portalEl = document.getElementById('portal');

interface Props {
  isOpen: boolean;
  onOverlayClick: () => void;
  onFinishAnimation?: () => void;
  withoutOverlay?: boolean;
}

export const Modal: FC<Props> = ({
  isOpen,
  onOverlayClick,
  onFinishAnimation,
  withoutOverlay,
  children,
}) => {
  const [isRender, setIsRender] = useState(isOpen);

  useEffect(() => {
    if (isOpen) {
      setIsRender(isOpen);
      document.body.classList.add('modal-is-open');
    } else if (isRender && portalEl && portalEl.children.length === 1) {
      document.body.classList.remove('modal-is-open');
    }
  }, [isOpen, isRender]);

  if (!portalEl || !isRender) {
    return null;
  }

  const moduleStyles = cn(styles.module, {
    [styles.closing]: !isOpen,
    [styles.withoutOverlay]: withoutOverlay,
  });

  const handleOverlayClick = (event: MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onOverlayClick();
    }
  };

  const handleAnimationEnd = () => {
    if (!isOpen) {
      setIsRender(false);
      if (onFinishAnimation) {
        onFinishAnimation();
      }
    }
  };

  return createPortal(
    <div
      className={moduleStyles}
      onClick={handleOverlayClick}
      onAnimationEnd={handleAnimationEnd}
    >
      <div className={styles.content}>{children}</div>
    </div>,
    portalEl
  );
};

import Swiper, { Navigation, Pagination } from 'swiper';
import GLightbox from 'glightbox';

Swiper.use([Navigation, Pagination]);

const initSlider = () => {
  new Swiper('.about__slider .swiper-container', {
    loop: true,
    spaceBetween: 20,

    navigation: {
      nextEl: '.about__next',
      prevEl: '.about__prev',
    },

    pagination: {
      el: '.about__pagination',
      clickable: true,
    },
  });
};

const initZoom = () => {
  GLightbox({
    selector: '.about__slide',
    loop: true,
  });
};

const initVideo = () => {
  GLightbox({
    selector: '.about__video',
    autoplayVideos: true,
  });
};

const about = {
  init: () => {
    initSlider();
    initZoom();
    initVideo();
  },
};

export default about;

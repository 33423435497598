import { FC, FormEvent, useContext } from 'react';
import { Input } from '../../_common/input';
import { Policy } from '../../_common/policy';
import { CallbackContext } from '../callback.context';
import styles from './callback-body.module.scss';

declare const ym: any;
declare const gtag: any;
declare const _tmr: any;

interface Props {
  onClose: (isNeedThanks?: boolean) => void;
}

export const CallbackBody: FC<Props> = ({ onClose }) => {
  const { state, dispatch } = useContext(CallbackContext);
  const { name, phone } = state;

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    ym(58297606, 'reachGoal', 'kick');
    gtag('event', 'submit', {
      event_category: 'form',
    });

    _tmr.push({ id: '3246592', type: 'reachGoal', goal: 'arrows' });

    onClose(true);

    const clientId = document.body.dataset.clientId;
    const source = document.body.dataset.source;

    await fetch('/ajax/_forms.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ form: 'callback', name, phone, clientId, source }),
    });
  };

  return (
    <div className={styles.module}>
      <button className={styles.close} onClick={() => onClose()} />
      <h3 className={styles.title}>Обратный звонок</h3>
      <form onSubmit={handleSubmit}>
        <Input
          moduleClass={styles.name}
          name="Имя:"
          size={10}
          value={name}
          onChange={(event) =>
            dispatch({ type: 'setName', name: event.target.value })
          }
        />
        <Input
          moduleClass={styles.phone}
          name="Тел:"
          size={10}
          type="tel"
          pattern="^\+?[0-9()\- ]+"
          required
          value={phone}
          onChange={(event) =>
            dispatch({ type: 'setPhone', phone: event.target.value })
          }
        />
        <Policy
          moduleClass={styles.policy}
          isChecked={state.policyIsChecked}
          onChange={() => dispatch({ type: 'togglePolicy' })}
          onClick={() => dispatch({ type: 'openPolicy' })}
        />
        <button className={styles.confirm}>Отправить</button>
      </form>
    </div>
  );
};

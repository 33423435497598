import { FC, RefObject, useEffect, useRef } from 'react';
import DayPicker, { DayModifiers } from 'react-day-picker';
import { createPortal } from 'react-dom';
import styles from './calendar.module.scss';
import { localization } from './localization';

const portalEl = document.getElementById('portal');

interface Props {
  isOpen: boolean;
  datePickerRef: RefObject<HTMLDivElement>;
  disabledDaysBefore: Date;
  onDateClick: (date: Date) => void;
  onClose: () => void;
  inModal?: boolean;
}

export const Calendar: FC<Props> = ({
  isOpen,
  datePickerRef,
  disabledDaysBefore,
  onDateClick,
  onClose,
  inModal,
}) => {
  const calendarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: Event) => {
      if (!calendarRef.current || !datePickerRef.current) {
        return;
      }

      if (
        !calendarRef.current.contains(event.target as HTMLElement) &&
        !datePickerRef.current.contains(event.target as HTMLElement)
      ) {
        onClose();
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [datePickerRef, onClose]);

  if (!portalEl || !isOpen || !datePickerRef.current) {
    return null;
  }

  const { top, left, height } = datePickerRef.current.getBoundingClientRect();

  let element: HTMLElement | null = datePickerRef.current;
  let currentTop = top + height;
  let currentLeft = left;

  while (element) {
    if (inModal && element === document.documentElement) {
      break;
    }
    currentTop += element.scrollTop;
    currentLeft += element.scrollLeft;
    element = element.parentElement;
  }

  const calendar = (
    <div
      className={styles.module}
      style={{ top: currentTop, left: currentLeft }}
      ref={calendarRef}
    >
      <DayPicker
        locale="ru"
        months={localization.months}
        weekdaysLong={localization.weekdaysLong}
        weekdaysShort={localization.weekdaysShort}
        labels={localization.labels}
        firstDayOfWeek={1}
        disabledDays={{ before: disabledDaysBefore }}
        onDayClick={(day: Date, modifiers: DayModifiers) => {
          if (modifiers.disabled) {
            return;
          }
          onDateClick(day);
          onClose();
        }}
      />
    </div>
  );

  return inModal ? calendar : createPortal(calendar, portalEl);
};

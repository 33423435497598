import React from 'react';
import ReactDOM from 'react-dom';
import { CallbackProvider } from '../components/callback/callback.context';
import { Callback } from '../components/callback';

const initButton = () => {
  const button = document.querySelector('.question__button');

  button?.addEventListener('click', () => {
    ReactDOM.render(
      <React.StrictMode>
        <CallbackProvider>
          <Callback />
        </CallbackProvider>
      </React.StrictMode>,
      document.getElementById('root')
    );
  });
};

const question = {
  init: () => {
    initButton();
  },
};

export default question;

import {
  ServiceCalculationState,
  ServiceCalculationAction,
} from './service-calculation.types';

export const serviceCalculationReducer = (
  state: ServiceCalculationState,
  action: ServiceCalculationAction
): ServiceCalculationState => {
  switch (action.type) {
    case 'closeModal':
      return { ...state, modalIsOpen: false };
    case 'openDate':
      return { ...state, dateIsOpen: true };
    case 'closeDate':
      return { ...state, dateIsOpen: false };
    case 'toggleDate':
      return { ...state, dateIsOpen: !state.dateIsOpen };
    case 'openThanks':
      return { ...state, thanksIsOpen: true };
    case 'closeThanks':
      return { ...state, thanksIsOpen: false };
    case 'setDate':
      return { ...state, date: action.date };
    case 'setTime':
      return { ...state, time: action.time };
    case 'setName':
      return { ...state, name: action.name };
    case 'setPhone':
      return { ...state, phone: action.phone };
    case 'togglePolicy':
      return { ...state, policyIsChecked: !state.policyIsChecked };
    case 'openPolicy':
      return { ...state, policyIsOpen: true };
    case 'closePolicy':
      return { ...state, policyIsOpen: false };
    case 'unmount':
      return { ...state, isMounted: false };

    default:
      return state;
  }
};

const initItems = () => {
  const items = document.querySelectorAll<HTMLDivElement>('.location__item');

  items.forEach((item) => {
    const image = item.querySelector<HTMLDivElement>('.location__image');
    image?.addEventListener('click', () => {
      const isActive = item.classList.contains('location__item--active');
      items.forEach((_item) =>
        _item.classList.remove('location__item--active')
      );
      if (!isActive) {
        item.classList.add('location__item--active');
      }
    });
  });

  document.addEventListener('click', (event) => {
    if (!event.target || !(event.target instanceof HTMLElement)) {
      return;
    }

    const target = event.target;

    if (Array.from(items).every((item) => !item.contains(target))) {
      items.forEach((_item) =>
        _item.classList.remove('location__item--active')
      );
    }
  });
};

const location = {
  init: () => {
    initItems();
  },
};

export default location;

import { FC, MouseEvent, useContext } from 'react';
import { CalculationContext } from '../calculation.context';
import styles from './calculation-control.module.scss';

export const CalculationControl: FC = () => {
  const { state, dispatch } = useContext(CalculationContext);

  const { step, arrival, departure } = state;

  const handleNext = (event: MouseEvent<HTMLButtonElement>) => {
    if (step === 1 && !arrival) {
      setTimeout(() => {
        dispatch({ type: 'openArrival' });
      });
      event.preventDefault();
      return;
    }
    if (step === 1 && !departure) {
      setTimeout(() => {
        dispatch({ type: 'openDeparture' });
      });
      event.preventDefault();
      return;
    }

    dispatch({ type: 'nextStep' });
  };

  return (
    <div className={styles.module}>
      {step === 2 && (
        <button
          className={styles.prev}
          type="button"
          onClick={() => dispatch({ type: 'prevStep' })}
        >
          Назад
        </button>
      )}
      {step < 3 && (
        <button className={styles.next} type="button" onClick={handleNext}>
          Далее
        </button>
      )}
      {step === 3 && (
        <button className={styles.confirm}>Получить расчет</button>
      )}
    </div>
  );
};

import React from 'react';
import ReactDOM from 'react-dom';
import { CalculationQuiz } from '../components/calculation-quiz';
import { CalculationQuizProvider } from '../components/calculation-quiz';

const initQuiz = () => {
  ReactDOM.render(
    <React.StrictMode>
      <CalculationQuizProvider>
        <CalculationQuiz />
      </CalculationQuizProvider>
    </React.StrictMode>,
    document.getElementById('quiz')
  );
};

const calculation = {
  init: () => {
    initQuiz();
  },
};

export default calculation;

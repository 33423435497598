import { FC } from 'react';
import styles from './review-body.module.scss';

interface Props {
  name: string;
  grade: string;
  text: string;
  onClose: () => void;
}

export const ReviewBody: FC<Props> = ({ name, grade, text, onClose }) => {
  return (
    <div className={styles.module}>
      <button className={styles.close} onClick={onClose} />
      <span className={styles.name}>{name}</span>
      <span className={styles.grade}>Оценка: {grade}</span>
      <p className={styles.text}>{text}</p>
    </div>
  );
};

import { FC, FormEvent, useContext, useRef } from 'react';

import styles from './calculation-body.module.scss';
import { Room } from '../calculation.types';
import { CalculationGallery } from '../calculation-gallery';
import { Quiz } from '../../_common/quiz';
import { CalculationContext } from '../calculation.context';
import { DatePicker } from '../../_common/date-picker';
import { Select } from '../../_common/select';
import { CalculationControl } from '../calculation-control';
import { Input } from '../../_common/input';
import { Policy } from '../../_common/policy';
import { getAdultOptions, getBabyOptions } from '../../../utils/utils';
import { Calendar } from '../../_common/calendar';
import { BabiesDropdown } from '../../_common/babies-dropdown';
import { Babies } from '../../_common/babies';

declare const ym: any;
declare const gtag: any;
declare const _tmr: any;

interface Props {
  room: Room;
  onClose: (isNeedThanks?: boolean) => void;
}

export const CalculationBody: FC<Props> = ({ room, onClose }) => {
  const { state, dispatch } = useContext(CalculationContext);
  const { arrival, departure, adults, babies, name, phone } = state;

  const arrivalRef = useRef<HTMLDivElement>(null);
  const departureRef = useRef<HTMLDivElement>(null);
  const babiesRef = useRef<HTMLDivElement>(null);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    ym(58297606, 'reachGoal', 'kick');
    gtag('event', 'submit', {
      event_category: 'form',
    });

    _tmr.push({ id: '3246592', type: 'reachGoal', goal: 'arrows' });

    onClose(true);

    const clientId = document.body.dataset.clientId;
    const source = document.body.dataset.source;

    await fetch('/ajax/_forms.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        form: 'calculation',
        room: room.name,
        arrival: arrival?.toLocaleDateString(),
        departure: departure?.toLocaleDateString(),
        adults,
        babies,
        name,
        phone,
        clientId,
        source,
      }),
    }).then(() => {
      dispatch({ type: 'closeModal' });
      dispatch({ type: 'openThanks' });
    });
  };

  return (
    <>
      <div className={styles.module}>
        <button className={styles.close} onClick={() => onClose()} />
        <h3 className={styles.title}>{room.name}</h3>
        <div className={styles.content}>
          <div className={styles.first}>
            <CalculationGallery images={room.images} />
          </div>
          <form className={styles.second} onSubmit={handleSubmit}>
            {/* <div className={styles.seats}>{room.seats}</div> */}
            <div className={styles.furniture}>
              {room.doubleBed && (
                <p>
                  <img src="/images/double-bed.svg" alt="double-bed" />
                  <span> x {room.doubleBed}</span>
                </p>
              )}
              {room.singleBed && (
                <p>
                  <img src="/images/single-bed.svg" alt="single-bed" />
                  <span> x {room.singleBed}</span>
                </p>
              )}
              {room.sofa && (
                <p>
                  <img src="/images/sofa.svg" alt="sofa" />
                  <span> x {room.sofa}</span>
                </p>
              )}
              {room.chair && (
                <p>
                  <img src="/images/chair.svg" alt="chair" />
                  <span> x {room.chair}</span>
                </p>
              )}
            </div>
            <div className={styles.text}>{room.text}</div>
            <div className={styles.properties}>
              {room.properties.map((property) => (
                <p className={styles.property}>
                  <img
                    src={`/images/${property.value}.svg`}
                    alt={property.label}
                  />
                  <span>{property.label}</span>
                </p>
              ))}
            </div>
            <div className={styles.prices}>
              {room.stockPrice ? (
                <>
                  <del>{room.price}₽</del>
                  <strong>от {room.stockPrice}₽</strong>
                </>
              ) : (
                <strong>от {room.price}₽</strong>
              )}
              <span>/ в сутки</span>
            </div>

            <p className={styles.quizTitle}>Это займет не более 2х минут</p>
            <p className={styles.steps}>Шаг {state.step} из 3:</p>
            <Quiz moduleClass={styles.quiz} step={state.step}>
              <div className={styles.step}>
                <DatePicker
                  moduleClass={styles.arrival}
                  name="Дата заезда:"
                  value={arrival}
                  onClick={() => dispatch({ type: 'toggleArrival' })}
                  ref={arrivalRef}
                />
                <DatePicker
                  moduleClass={styles.departure}
                  name="Дата отъезда:"
                  value={departure}
                  onClick={() => dispatch({ type: 'toggleDeparture' })}
                  ref={departureRef}
                />
              </div>
              <div className={styles.step}>
                <Select
                  moduleClass={styles.adults}
                  options={getAdultOptions()}
                  paddingLeft={170}
                  name="Кол-во взрослых:"
                  value={adults}
                  onChange={(event) =>
                    dispatch({ type: 'setAdults', adults: +event.target.value })
                  }
                />
                <Select
                  moduleClass={styles.babiesSelect}
                  options={getBabyOptions()}
                  paddingLeft={142}
                  name="Кол-во детей:"
                  value={babies.length}
                  onChange={(event) =>
                    dispatch({ type: 'setBabies', babies: +event.target.value })
                  }
                />
                {babies.length > 0 && (
                  <Babies
                    moduleClass={styles.babies}
                    babies={babies}
                    onClick={() => dispatch({ type: 'toggleBabies' })}
                    ref={babiesRef}
                  />
                )}
              </div>
              <div className={styles.step}>
                <Input
                  moduleClass={styles.name}
                  name="Имя:"
                  size={10}
                  value={name}
                  onChange={(event) =>
                    dispatch({ type: 'setName', name: event.target.value })
                  }
                />
                <Input
                  moduleClass={styles.phone}
                  name="Тел:"
                  size={10}
                  type="tel"
                  pattern="^\+?[0-9()\- ]+"
                  required
                  value={phone}
                  onChange={(event) =>
                    dispatch({ type: 'setPhone', phone: event.target.value })
                  }
                />
                <Policy
                  moduleClass={styles.policy}
                  isChecked={state.policyIsChecked}
                  onChange={() => dispatch({ type: 'togglePolicy' })}
                  onClick={() => dispatch({ type: 'openPolicy' })}
                />
              </div>
            </Quiz>
            <CalculationControl />
          </form>
        </div>
      </div>
      <Calendar
        isOpen={state.arrivalIsOpen}
        datePickerRef={arrivalRef}
        disabledDaysBefore={new Date()}
        onDateClick={(date) => dispatch({ type: 'setArrival', arrival: date })}
        onClose={() => dispatch({ type: 'closeArrival' })}
        inModal
      />
      <Calendar
        isOpen={state.departureIsOpen}
        datePickerRef={departureRef}
        disabledDaysBefore={arrival || new Date()}
        onDateClick={(date) =>
          dispatch({ type: 'setDeparture', departure: date })
        }
        onClose={() => dispatch({ type: 'closeDeparture' })}
        inModal
      />
      <BabiesDropdown
        isOpen={state.babiesIsOpen}
        babiesRef={babiesRef}
        babies={babies}
        onChangeBaby={(value, idx) =>
          dispatch({ type: 'changeBaby', value, idx })
        }
        onClose={() => dispatch({ type: 'closeBabies' })}
        inModal
      />
    </>
  );
};

import React from 'react';
import ReactDOM from 'react-dom';

import Swiper, { Navigation, Pagination } from 'swiper';
import GLightbox from 'glightbox';
import { CalculationProvider } from '../components/calculation/calculation.context';
import { Calculation } from '../components/calculation/calculation';
import { BookingProvider } from '../components/booking/booking.context';
import { Booking } from '../components/booking';

Swiper.use([Navigation, Pagination]);

const initSliders = () => {
  const items = document.querySelectorAll<HTMLDivElement>('.rooms__block');

  items.forEach((item) => {
    const slider = item.querySelector<HTMLDivElement>('.swiper-container');
    const prev = item.querySelector<HTMLButtonElement>('.rooms__prev');
    const next = item.querySelector<HTMLButtonElement>('.rooms__next');
    const pagination = item.querySelector<HTMLDivElement>('.rooms__pagination');

    new Swiper(slider!, {
      spaceBetween: 12,
      slidesPerView: 1,

      navigation: {
        prevEl: prev,
        nextEl: next,
      },

      pagination: {
        el: pagination,
        clickable: true,
      },

      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    });
  });
};

const initInnerPagination = () => {
  const items = document.querySelectorAll<HTMLDivElement>('.rooms__wrapper');

  items.forEach((item) => {
    const imageWrapper = item.querySelector<HTMLDivElement>('.rooms__images');
    const prev = item.querySelector<HTMLButtonElement>('.rooms__inner-prev');
    const next = item.querySelector<HTMLButtonElement>('.rooms__inner-next');
    const paginationSpans = item.querySelectorAll<HTMLSpanElement>(
      '.rooms__inner-pagination-item'
    );

    let counter = 0;
    const quantity = imageWrapper?.children.length || 0;

    prev?.addEventListener('click', () => {
      if (counter > 0) {
        imageWrapper!.style.transform = `translate3d(${
          --counter * -100
        }%, 0, 0)`;
        paginationSpans.forEach((span) =>
          span.classList.remove('rooms__inner-pagination-item--active')
        );
        paginationSpans[counter].classList.add(
          'rooms__inner-pagination-item--active'
        );
      }
    });

    next?.addEventListener('click', () => {
      if (counter < quantity - 1) {
        imageWrapper!.style.transform = `translate3d(${
          ++counter * -100
        }%, 0, 0)`;
      }
      paginationSpans.forEach((span) =>
        span.classList.remove('rooms__inner-pagination-item--active')
      );
      paginationSpans[counter].classList.add(
        'rooms__inner-pagination-item--active'
      );
    });
  });
};

const initZoom = () => {
  const imageContainers =
    document.querySelectorAll<HTMLDivElement>('.rooms__images');
  imageContainers.forEach((container) => {
    const images = container.querySelectorAll<HTMLDivElement>('.rooms__image');

    const items = Array.from(images).map((item) => ({
      href: item.getAttribute('href'),
    }));

    const glightbox = GLightbox({
      elements: items,
      loop: true,
    });

    images.forEach((image) => {
      image?.addEventListener('click', (event) => {
        event.preventDefault();
        glightbox.openAt(image.dataset.index);
      });
    });
  });
};

const initCalculation = () => {
  const calculateItems = document.querySelectorAll<HTMLDivElement>(
    '.rooms__calculation'
  );

  const calculateDetailItems =
    document.querySelectorAll<HTMLDivElement>('.rooms__detail');

  [...Array.from(calculateItems), ...Array.from(calculateDetailItems)].forEach(
    (item) =>
      item.addEventListener('click', () => {
        const {
          id,
          name,
          images,
          text,
          price,
          stockPrice,
          singleBed,
          doubleBed,
          sofa,
          chair,
          seats,
          properties,
        } = item.dataset;

        window.history.replaceState(null, '', `/?room=${id}`);

        // const a = stockPrice ?? 0;
        ReactDOM.render(
          <React.StrictMode>
            <CalculationProvider>
              <Calculation
                room={{
                  id: id!,
                  name: name!,
                  images: images!.split(','),
                  text: text!,
                  price: +price!,
                  stockPrice: stockPrice ? +stockPrice : undefined,
                  singleBed: singleBed ? +singleBed : undefined,
                  doubleBed: doubleBed ? +doubleBed : undefined,
                  sofa: sofa ? +sofa : undefined,
                  chair: chair ? +chair : undefined,
                  seats: seats!,
                  properties: JSON.parse(properties!),
                }}
              />
            </CalculationProvider>
          </React.StrictMode>,
          document.getElementById('root')
        );
      })
  );
};

const checkUrlParam = () => {
  const url = new URL(window.location.toString());
  const roomId = url.searchParams.get('room');

  if (!roomId) {
    return;
  }

  const calculationButton = document.querySelector<HTMLButtonElement>(
    `.rooms__calculation[data-id="${roomId}"]`
  );

  if (!calculationButton) {
    return;
  }

  const {
    id,
    name,
    images,
    text,
    price,
    stockPrice,
    singleBed,
    doubleBed,
    sofa,
    chair,
    seats,
    properties,
  } = calculationButton.dataset;

  ReactDOM.render(
    <React.StrictMode>
      <CalculationProvider>
        <Calculation
          room={{
            id: id!,
            name: name!,
            images: images!.split(','),
            text: text!,
            price: +price!,
            stockPrice: stockPrice ? +stockPrice : undefined,
            singleBed: singleBed ? +singleBed : undefined,
            doubleBed: doubleBed ? +doubleBed : undefined,
            sofa: sofa ? +sofa : undefined,
            chair: chair ? +chair : undefined,
            seats: seats!,
            properties: JSON.parse(properties!),
          }}
        />
      </CalculationProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

const initBooking = () => {
  const bookingItems =
    document.querySelectorAll<HTMLButtonElement>('.rooms__booking');

  bookingItems.forEach((item) =>
    item.addEventListener('click', () =>
      ReactDOM.render(
        <React.StrictMode>
          <BookingProvider>
            <Booking kind="booking" room={item.dataset.name!} />
          </BookingProvider>
        </React.StrictMode>,
        document.getElementById('root')
      )
    )
  );
};

const rooms = {
  init: () => {
    initSliders();
    initInnerPagination();
    initZoom();
    initCalculation();
    checkUrlParam();
    initBooking();
  },
};

export default rooms;

import { createContext, Dispatch, FC, useReducer } from 'react';
import { questionReducer } from './question.reducer';
import { QuestionState, QuestionAction } from './question.types';

const initialState: QuestionState = {
  name: '',
  phone: '',
  text: '',
  policyIsChecked: false,
  modalIsOpen: true,
  thanksIsOpen: false,
  policyIsOpen: false,
  isMounted: true,
};

export const QuestionContext = createContext<{
  state: QuestionState;
  dispatch: Dispatch<QuestionAction>;
}>({ state: initialState, dispatch: () => null });

export const QuestionProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(questionReducer, initialState);

  return (
    <QuestionContext.Provider value={{ state, dispatch }}>
      {children}
    </QuestionContext.Provider>
  );
};

import { FC, FormEvent, useContext, useRef } from 'react';
import { getAdultOptions, getBabyOptions } from '../../../utils/utils';
import { Babies } from '../../_common/babies';
import { BabiesDropdown } from '../../_common/babies-dropdown';
import { Calendar } from '../../_common/calendar';
import { DatePicker } from '../../_common/date-picker';
import { Input } from '../../_common/input';
import { Policy } from '../../_common/policy';
import { Quiz } from '../../_common/quiz';
import { Select } from '../../_common/select';
import { CalculationQuizControl } from '../calculation-quiz-control';
import { CalculationQuizContext } from '../calculation-quiz.context';
import styles from './calculation-quiz-body.module.scss';

declare const ym: any;
declare const gtag: any;
declare const _tmr: any;

export const CalculationQuizBody: FC = () => {
  const { state, dispatch } = useContext(CalculationQuizContext);

  const { arrival, departure, adults, babies, name, phone } = state;

  const arrivalRef = useRef<HTMLDivElement>(null);
  const departureRef = useRef<HTMLDivElement>(null);
  const babiesRef = useRef<HTMLDivElement>(null);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    ym(58297606, 'reachGoal', 'kick');
    gtag('event', 'submit', {
      event_category: 'form',
    });

    _tmr.push({ id: '3246592', type: 'reachGoal', goal: 'arrows' });

    dispatch({ type: 'openThanks' });

    const clientId = document.body.dataset.clientId;
    const source = document.body.dataset.source;

    await fetch('/ajax/_forms.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        form: 'calculation',
        arrival: arrival?.toLocaleDateString(),
        departure: departure?.toLocaleDateString(),
        adults,
        babies,
        name,
        phone,
        clientId,
        source,
      }),
    });
  };

  return (
    <>
      <form className={styles.module} onSubmit={handleSubmit}>
        <p className={styles.title}>Это займет не более 2х минут</p>
        <p className={styles.steps}>Шаг {state.step} из 3:</p>
        <Quiz moduleClass={styles.quiz} step={state.step}>
          <div className={styles.step}>
            <DatePicker
              moduleClass={styles.arrival}
              name="Дата заезда:"
              value={arrival}
              onClick={() => dispatch({ type: 'toggleArrival' })}
              ref={arrivalRef}
            />
            <DatePicker
              moduleClass={styles.departure}
              name="Дата отъезда:"
              value={departure}
              onClick={() => dispatch({ type: 'toggleDeparture' })}
              ref={departureRef}
            />
          </div>
          <div className={styles.step}>
            <Select
              moduleClass={styles.adults}
              options={getAdultOptions()}
              paddingLeft={170}
              name="Кол-во взрослых:"
              value={adults}
              onChange={(event) =>
                dispatch({ type: 'setAdults', adults: +event.target.value })
              }
            />
            <Select
              moduleClass={styles.babiesSelect}
              options={getBabyOptions()}
              paddingLeft={142}
              name="Кол-во детей:"
              value={babies.length}
              onChange={(event) =>
                dispatch({ type: 'setBabies', babies: +event.target.value })
              }
            />
            {babies.length > 0 && (
              <Babies
                moduleClass={styles.babies}
                babies={babies}
                onClick={() => dispatch({ type: 'toggleBabies' })}
                ref={babiesRef}
              />
            )}
          </div>
          <div className={styles.step}>
            <Input
              moduleClass={styles.name}
              name="Имя:"
              size={10}
              value={name}
              onChange={(event) =>
                dispatch({ type: 'setName', name: event.target.value })
              }
            />
            <Input
              moduleClass={styles.phone}
              name="Тел:"
              size={10}
              type="tel"
              pattern="^\+?[0-9()\- ]+"
              required
              value={phone}
              onChange={(event) =>
                dispatch({ type: 'setPhone', phone: event.target.value })
              }
            />
            <Policy
              moduleClass={styles.policy}
              isChecked={state.policyIsChecked}
              onChange={() => dispatch({ type: 'togglePolicy' })}
              onClick={() => dispatch({ type: 'openPolicy' })}
            />
          </div>
        </Quiz>
        <CalculationQuizControl />
      </form>
      <Calendar
        isOpen={state.arrivalIsOpen}
        datePickerRef={arrivalRef}
        disabledDaysBefore={new Date()}
        onDateClick={(date) => dispatch({ type: 'setArrival', arrival: date })}
        onClose={() => dispatch({ type: 'closeArrival' })}
      />
      <Calendar
        isOpen={state.departureIsOpen}
        datePickerRef={departureRef}
        disabledDaysBefore={arrival || new Date()}
        onDateClick={(date) =>
          dispatch({ type: 'setDeparture', departure: date })
        }
        onClose={() => dispatch({ type: 'closeDeparture' })}
      />
      <BabiesDropdown
        isOpen={state.babiesIsOpen}
        babiesRef={babiesRef}
        babies={babies}
        onChangeBaby={(value, idx) =>
          dispatch({ type: 'changeBaby', value, idx })
        }
        onClose={() => dispatch({ type: 'closeBabies' })}
      />
    </>
  );
};

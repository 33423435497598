import { createContext, Dispatch, FC, useReducer } from 'react';
import { callbackReducer } from './callback.reducer';
import { CallbackState, CallbackAction } from './callback.types';

const initialState: CallbackState = {
  name: '',
  phone: '',
  policyIsChecked: false,
  modalIsOpen: true,
  thanksIsOpen: false,
  policyIsOpen: false,
  isMounted: true,
};

export const CallbackContext = createContext<{
  state: CallbackState;
  dispatch: Dispatch<CallbackAction>;
}>({ state: initialState, dispatch: () => null });

export const CallbackProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(callbackReducer, initialState);

  return (
    <CallbackContext.Provider value={{ state, dispatch }}>
      {children}
    </CallbackContext.Provider>
  );
};

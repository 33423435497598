import { FC, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Controller, Navigation, Pagination } from 'swiper';
import GLightbox from 'glightbox';
import cn from 'classnames';
import styles from './calculation-gallery.module.scss';

SwiperCore.use([Navigation, Pagination, Controller]);

interface Props {
  images: string[];
}

export const CalculationGallery: FC<Props> = ({ images }) => {
  const [swiper, setSwiper] = useState<SwiperCore>();

  useEffect(() => {
    const glightbox = GLightbox({
      selector: '.calculation-slide-glighbox',
    });
    return () => glightbox.destroy();
  });

  // const handleThumbClick = (idx: number) => {
  //   swiper?.slideTo(idx);
  // };

  const slideStyles = cn(styles.slide, 'calculation-slide-glighbox');
  const thumbStyles = cn(styles.thumbImage, 'calculation-slide-glighbox');

  return (
    <div className={styles.module}>
      <div className={styles.slider}>
        <Swiper
          spaceBetween={10}
          slidesPerView={1}
          loop
          navigation={{
            prevEl: '.calculation-swiper-nav-prev',
            nextEl: '.calculation-swiper-nav-next',
          }}
          pagination={{
            el: '.calculation-swiper-pagination',
            clickable: true,
          }}
          onSwiper={setSwiper}
        >
          {images.map((image, idx) => (
            <SwiperSlide key={idx}>
              <a className={slideStyles} href={image}>
                <img src={image} alt="calculation" />
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="calculation-swiper-nav">
          <button className="calculation-swiper-nav-prev" />
          <button className="calculation-swiper-nav-next" />
        </div>
        <div className="calculation-swiper-pagination" />
      </div>
      <div className={styles.thumbs}>
        {images.map((image, idx, items) => (
          <div
            key={idx}
            className={styles.thumb}
            style={{ display: idx > 2 ? 'none' : 'block' }}
          >
            {idx === 2 && <span>+{items.length - 3}</span>}
            <a className={thumbStyles} href={image}>
              <img src={image} alt="thumb" />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

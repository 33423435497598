import { createContext, Dispatch, FC, useReducer } from 'react';
import { calculationQuizReducer } from './calculation-quiz.reducer';
import {
  CalculationQuizState,
  CalculationQuizAction,
} from './calculation-quiz.types';

const initialState: CalculationQuizState = {
  step: 1,
  arrival: undefined,
  departure: undefined,
  adults: 2,
  babies: [],
  name: '',
  phone: '',
  policyIsChecked: false,

  arrivalIsOpen: false,
  departureIsOpen: false,
  babiesIsOpen: false,
  thanksIsOpen: false,
  policyIsOpen: false,
  isMounted: true,
};

export const CalculationQuizContext = createContext<{
  state: CalculationQuizState;
  dispatch: Dispatch<CalculationQuizAction>;
}>({ state: initialState, dispatch: () => null });

export const CalculationQuizProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(calculationQuizReducer, initialState);

  return (
    <CalculationQuizContext.Provider value={{ state, dispatch }}>
      {children}
    </CalculationQuizContext.Provider>
  );
};

import { FC, RefObject, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { Select } from '../select';
import styles from './babies-dropdown.module.scss';
import { ageOptions } from './utils';

const portalEl = document.getElementById('portal');

interface Props {
  isOpen: boolean;
  babiesRef: RefObject<HTMLDivElement>;
  babies: number[];
  onChangeBaby: (value: number, idx: number) => void;
  onClose: () => void;
  inModal?: boolean;
}

export const BabiesDropdown: FC<Props> = ({
  isOpen,
  babiesRef,
  babies,
  onChangeBaby,
  onClose,
  inModal,
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: Event) => {
      if (!dropdownRef.current || !babiesRef.current) {
        return;
      }

      if (
        !dropdownRef.current.contains(event.target as HTMLElement) &&
        !babiesRef.current.contains(event.target as HTMLElement)
      ) {
        onClose();
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [babiesRef, onClose]);

  if (!portalEl || !isOpen || !babiesRef.current) {
    return null;
  }

  const { top, left, height } = babiesRef.current.getBoundingClientRect();

  let element: HTMLElement | null = babiesRef.current;
  let currentTop = top + height;
  let currentLeft = left;

  while (element) {
    if (inModal && element === document.documentElement) {
      break;
    }
    currentTop += element.scrollTop;
    currentLeft += element.scrollLeft;
    element = element.parentElement;
  }

  const dropdown = (
    <div
      className={styles.module}
      style={{ top: currentTop, left: currentLeft }}
      ref={dropdownRef}
    >
      {babies.map((item, idx) => (
        <div className={styles.item} key={idx}>
          <span className={styles.name}>Ребенок {idx + 1}</span>
          <Select
            moduleClass={styles.select}
            options={ageOptions}
            value={item}
            paddingLeft={20}
            onChange={(event) => onChangeBaby(+event.target.value, idx)}
          />
        </div>
      ))}
      <button className={styles.button} onClick={() => onClose()}>
        Готово
      </button>
    </div>
  );

  return inModal ? dropdown : createPortal(dropdown, portalEl);
};

import React from 'react';
import ReactDOM from 'react-dom';

import Swiper, { Navigation, Pagination } from 'swiper';
import { Service } from '../components/service';
import { ServiceCalculation } from '../components/service-calculation';
import { ServiceCalculationProvider } from '../components/service-calculation/service-calculation.context';

Swiper.use([Navigation, Pagination]);

const initSlider = () => {
  new Swiper('.services__slider .swiper-container', {
    loop: true,
    spaceBetween: 10,
    loopedSlides: 10,

    navigation: {
      nextEl: '.services__next',
      prevEl: '.services__prev',
    },

    pagination: {
      el: '.services__pagination',
    },

    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 15,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 30,
        loop: false,
        allowTouchMove: false,
      },
    },
  });
};

const initDetail = () => {
  const items =
    document.querySelectorAll<HTMLButtonElement>('.services__detail');
  items.forEach((item) =>
    item.addEventListener('click', () =>
      ReactDOM.render(
        <React.StrictMode>
          <ServiceCalculationProvider>
            <Service
              service={item.dataset.name!}
              workTime={item.dataset.workTime!.split(',')}
              images={item.dataset.images!.split(',')}
              text={item.dataset.text!}
            />
          </ServiceCalculationProvider>
        </React.StrictMode>,
        document.getElementById('root')
      )
    )
  );
};

const initImageDetail = () => {
  const items =
    document.querySelectorAll<HTMLButtonElement>('.services__image');
  items.forEach((item) =>
    item.addEventListener('click', () =>
      ReactDOM.render(
        <React.StrictMode>
          <ServiceCalculationProvider>
            <Service
              service={item.dataset.name!}
              workTime={item.dataset.workTime!.split(',')}
              images={item.dataset.images!.split(',')}
              text={item.dataset.text!}
            />
          </ServiceCalculationProvider>
        </React.StrictMode>,
        document.getElementById('root')
      )
    )
  );
};

const initCalculate = () => {
  const items =
    document.querySelectorAll<HTMLButtonElement>('.services__booking');
  items.forEach((item) =>
    item.addEventListener('click', () =>
      ReactDOM.render(
        <React.StrictMode>
          <ServiceCalculationProvider>
            <ServiceCalculation
              service={item.dataset.name!}
              workTime={item.dataset.workTime!.split(',')}
            />
          </ServiceCalculationProvider>
        </React.StrictMode>,
        document.getElementById('service-root')
      )
    )
  );
};

const services = {
  init: () => {
    initSlider();
    initDetail();
    initImageDetail();
    initCalculate();
  },
};

export default services;

import React from 'react';
import ReactDOM from 'react-dom';

import Swiper, { Navigation, Pagination } from 'swiper';
import { AddReview } from '../components/add-review';
import { AddReviewProvider } from '../components/add-review';
import { Review } from '../components/review';

Swiper.use([Navigation, Pagination]);

const initSlider = () => {
  new Swiper('.reviews__slider .swiper-container', {
    loop: true,
    spaceBetween: 10,
    // centeredSlides: true,
    loopedSlides: 10,

    navigation: {
      nextEl: '.reviews__next',
      prevEl: '.reviews__prev',
    },

    pagination: {
      el: '.reviews__pagination',
      clickable: true,
    },

    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 15,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
  });
};

const initReviewsHeight = () => {
  document.addEventListener('DOMContentLoaded', () => {
    const items = document.querySelectorAll<HTMLDivElement>('.reviews__slide');

    let height = 200;
    if (window.innerWidth >= 768) {
      height = 200;
    }

    items.forEach((item) => {
      const p = item.querySelector<HTMLParagraphElement>('.reviews__text');
      if (p!.offsetHeight > height) {
        item.classList.add('reviews__slide--cut');
      }
    });
  });
};

const initDetail = () => {
  const detailItems = document.querySelectorAll<HTMLButtonElement>(
    '.reviews__button'
  );

  detailItems.forEach((item) =>
    item.addEventListener('click', () =>
      ReactDOM.render(
        <React.StrictMode>
          <Review
            name={item.dataset.name!}
            grade={item.dataset.grade!}
            text={item.dataset.text!}
          />
        </React.StrictMode>,
        document.getElementById('root')
      )
    )
  );
};

const initAddReview = () => {
  const button = document.querySelector('.reviews__add');

  button?.addEventListener('click', () =>
    ReactDOM.render(
      <React.StrictMode>
        <AddReviewProvider>
          <AddReview />
        </AddReviewProvider>
      </React.StrictMode>,
      document.getElementById('root')
    )
  );
};

const reviews = {
  init: () => {
    initSlider();
    initDetail();
    initAddReview();
    initReviewsHeight();
  },
};

export default reviews;

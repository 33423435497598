import { FC, FormEvent, MouseEvent, useContext, useRef } from 'react';
import { Calendar } from '../../_common/calendar';
import { DatePicker } from '../../_common/date-picker';
import { Input } from '../../_common/input';
import { Policy } from '../../_common/policy';
import { Select } from '../../_common/select';
import { ServiceCalculationContext } from '../service-calculation.context';
import styles from './service-calculation-body.module.scss';

declare const ym: any;
declare const gtag: any;
declare const _tmr: any;

interface Props {
  service: string;
  workTime: string[];
  onClose: (isNeedThanks?: boolean) => void;
}

export const ServiceCalculationBody: FC<Props> = ({
  service,
  workTime,
  onClose,
}) => {
  const { state, dispatch } = useContext(ServiceCalculationContext);
  const { date, time, name, phone } = state;

  const dateRef = useRef<HTMLDivElement>(null);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    ym(58297606, 'reachGoal', 'kick');
    gtag('event', 'submit', {
      event_category: 'form',
    });

    _tmr.push({ id: '3246592', type: 'reachGoal', goal: 'arrows' });

    onClose(true);

    const clientId = document.body.dataset.clientId;
    const source = document.body.dataset.source;

    await fetch('/ajax/_forms.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        form: 'service',
        service,
        date: date?.toLocaleDateString(),
        time,
        name,
        phone,
        clientId,
        source,
      }),
    }).then(() => {
      dispatch({ type: 'closeModal' });
      dispatch({ type: 'openThanks' });
    });
  };

  const handleConfirm = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (!date) {
      dispatch({ type: 'openDate' });
      event.preventDefault();
    }
  };

  return (
    <>
      <div className={styles.module}>
        <button className={styles.close} onClick={() => onClose()} />
        <h3 className={styles.title}>{service}</h3>
        <form onSubmit={handleSubmit}>
          <DatePicker
            moduleClass={styles.date}
            name="Дата:"
            value={date}
            onClick={() => dispatch({ type: 'toggleDate' })}
            ref={dateRef}
          />
          <div className={styles.timeBlock}>
            <img src="/images/clock.svg" alt="clock" />
            <Select
              moduleClass={styles.time}
              options={['', ...workTime].map((time) => ({
                value: time,
                text: time,
              }))}
              paddingLeft={93}
              name="Время:"
              value={time}
              onChange={(event) =>
                dispatch({ type: 'setTime', time: event.target.value })
              }
            />
          </div>
          <Input
            moduleClass={styles.name}
            name="Имя:"
            size={10}
            value={name}
            onChange={(event) =>
              dispatch({ type: 'setName', name: event.target.value })
            }
          />
          <Input
            moduleClass={styles.phone}
            name="Тел:"
            size={10}
            type="tel"
            pattern="^\+?[0-9()\- ]+"
            required
            value={phone}
            onChange={(event) =>
              dispatch({ type: 'setPhone', phone: event.target.value })
            }
          />
          <Policy
            moduleClass={styles.policy}
            isChecked={state.policyIsChecked}
            onChange={() => dispatch({ type: 'togglePolicy' })}
            onClick={() => dispatch({ type: 'openPolicy' })}
          />
          <button className={styles.confirm} onClick={handleConfirm}>
            Отправить
          </button>
        </form>
      </div>
      <Calendar
        isOpen={state.dateIsOpen}
        datePickerRef={dateRef}
        disabledDaysBefore={new Date()}
        onDateClick={(date) => dispatch({ type: 'setDate', date: date })}
        onClose={() => dispatch({ type: 'closeDate' })}
        inModal
      />
    </>
  );
};

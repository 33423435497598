import { FC } from 'react';
import cn from 'classnames';
import styles from './quiz.module.scss';

interface Props {
  step: number;
  moduleClass?: string;
}

export const Quiz: FC<Props> = ({ step, moduleClass, children }) => {
  const moduleStyles = cn(styles.module, moduleClass);

  return (
    <div className={moduleStyles}>
      <div
        className={styles.wrapper}
        style={{ transform: `translateX(${(step - 1) * -100}%)` }}
      >
        {children}
      </div>
    </div>
  );
};

import { CallbackState, CallbackAction } from './callback.types';

export const callbackReducer = (
  state: CallbackState,
  action: CallbackAction
): CallbackState => {
  switch (action.type) {
    case 'closeModal':
      return { ...state, modalIsOpen: false };
    case 'openThanks':
      return { ...state, thanksIsOpen: true };
    case 'closeThanks':
      return { ...state, thanksIsOpen: false };
    case 'setName':
      return { ...state, name: action.name };
    case 'setPhone':
      return { ...state, phone: action.phone };
    case 'togglePolicy':
      return { ...state, policyIsChecked: !state.policyIsChecked };
    case 'openPolicy':
      return { ...state, policyIsOpen: true };
    case 'closePolicy':
      return { ...state, policyIsOpen: false };
    case 'unmount':
      return { ...state, isMounted: false };

    default:
      return state;
  }
};

import { FC, useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Modal } from '../_common/modal';
import { Textual } from '../_common/textual';
import { Thanks } from '../_common/thanks';
import { BookingBody } from './booking-body';
import { BookingContext } from './booking.context';

interface Props {
  kind: 'booking' | 'calculation';
  room?: string;
}

export const Booking: FC<Props> = ({ kind, room }) => {
  const { state, dispatch } = useContext(BookingContext);

  useEffect(() => {
    if (!state.isMounted) {
      ReactDOM.unmountComponentAtNode(document.getElementById('root')!);
    }
  }, [state.isMounted]);

  const checkUnmount = () => {
    if (!state.modalIsOpen && !state.thanksIsOpen && !state.policyIsOpen) {
      dispatch({ type: 'unmount' });
    }
  };

  return (
    <>
      <Modal
        isOpen={state.modalIsOpen}
        onOverlayClick={() => dispatch({ type: 'closeModal' })}
        onFinishAnimation={() => checkUnmount()}
      >
        <BookingBody
          kind={kind}
          onClose={(isNeedThanks) => {
            dispatch({ type: 'closeModal' });
            if (isNeedThanks) {
              dispatch({ type: 'openThanks' });
            }
          }}
          room={room}
        />
      </Modal>
      <Modal
        isOpen={state.thanksIsOpen}
        onOverlayClick={() => dispatch({ type: 'closeThanks' })}
        onFinishAnimation={() => checkUnmount()}
      >
        <Thanks onClose={() => dispatch({ type: 'closeThanks' })} />
      </Modal>
      <Modal
        isOpen={state.policyIsOpen}
        onOverlayClick={() => dispatch({ type: 'closePolicy' })}
        onFinishAnimation={() => checkUnmount()}
        withoutOverlay
      >
        <Textual
          type="policy"
          onClose={() => dispatch({ type: 'closePolicy' })}
        />
      </Modal>
    </>
  );
};

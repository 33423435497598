import { FC, SelectHTMLAttributes } from 'react';
import cn from 'classnames';
import styles from './select.module.scss';

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  options: { value: number | string; text: string }[];
  paddingLeft: number;
  name?: string;
  moduleClass?: string;
}

export const Select: FC<Props> = ({
  options,
  paddingLeft,
  name,
  moduleClass,
  ...rest
}) => {
  const moduleStyles = cn(styles.module, moduleClass);

  return (
    <label className={moduleStyles}>
      <span className={styles.name}>{name}</span>
      <select
        className={styles.select}
        {...rest}
        style={{ paddingLeft: `${paddingLeft}px` }}
      >
        {options.map((item, idx) => (
          <option key={idx} value={item.value}>
            {item.text}
          </option>
        ))}
      </select>
    </label>
  );
};

import { FC, FormEvent, useContext } from 'react';
import { Input } from '../../_common/input';
import { Policy } from '../../_common/policy';
import { Select } from '../../_common/select';
import { Textarea } from '../../_common/textarea';
import { AddReviewContext } from '../add-review.context';
import styles from './add-review-body.module.scss';

interface Props {
  onClose: (isNeedThanks?: boolean) => void;
}

export const AddReviewBody: FC<Props> = ({ onClose }) => {
  const { state, dispatch } = useContext(AddReviewContext);
  const { name, email, grade, text } = state;

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    await fetch('/ajax/addReview.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, email, grade, text }),
    });

    onClose(true);
  };

  return (
    <div className={styles.module}>
      <button className={styles.close} onClick={() => onClose()} />
      <h3 className={styles.title}>Оставить отзыв</h3>
      <form onSubmit={handleSubmit}>
        <Select
          moduleClass={styles.grade}
          options={[1, 2, 3, 4, 5].map((item) => ({
            value: item,
            text: `${item}`,
          }))}
          paddingLeft={100}
          name="Оценка:"
          value={grade}
          onChange={(event) =>
            dispatch({ type: 'setGrade', grade: +event.target.value })
          }
        />
        <Textarea
          moduleClass={styles.text}
          placeholder="Текст"
          required
          value={text}
          onChange={(event) =>
            dispatch({ type: 'setText', text: event.target.value })
          }
        />
        <Input
          moduleClass={styles.name}
          name="Имя:"
          size={10}
          required
          value={name}
          onChange={(event) =>
            dispatch({ type: 'setName', name: event.target.value })
          }
        />
        <Input
          moduleClass={styles.email}
          name="E-mail:"
          size={10}
          required
          type="email"
          value={email}
          onChange={(event) =>
            dispatch({ type: 'setEmail', email: event.target.value })
          }
        />
        <Policy
          moduleClass={styles.policy}
          isChecked={state.policyIsChecked}
          onChange={() => dispatch({ type: 'togglePolicy' })}
          onClick={() => dispatch({ type: 'openPolicy' })}
        />
        <button className={styles.confirm}>Отправить</button>
      </form>
    </div>
  );
};

import { CalculationState, CalculationAction } from './calculation.types';

export const calculationReducer = (
  state: CalculationState,
  action: CalculationAction
): CalculationState => {
  switch (action.type) {
    case 'closeModal':
      return { ...state, modalIsOpen: false };
    case 'openArrival':
      return { ...state, arrivalIsOpen: true };
    case 'closeArrival':
      return { ...state, arrivalIsOpen: false };
    case 'toggleArrival':
      return { ...state, arrivalIsOpen: !state.arrivalIsOpen };
    case 'openDeparture':
      return { ...state, departureIsOpen: true };
    case 'closeDeparture':
      return { ...state, departureIsOpen: false };
    case 'toggleDeparture':
      return { ...state, departureIsOpen: !state.departureIsOpen };
    case 'openBabies':
      return { ...state, babiesIsOpen: true };
    case 'closeBabies':
      return { ...state, babiesIsOpen: false };
    case 'toggleBabies':
      return { ...state, babiesIsOpen: !state.babiesIsOpen };
    case 'prevStep':
      return { ...state, step: state.step - 1 };
    case 'nextStep':
      return { ...state, step: state.step + 1 };
    case 'openThanks':
      return { ...state, thanksIsOpen: true };
    case 'closeThanks':
      return { ...state, thanksIsOpen: false };
    case 'setArrival':
      return { ...state, arrival: action.arrival };
    case 'setDeparture':
      return { ...state, departure: action.departure };
    case 'setAdults':
      return { ...state, adults: action.adults };
    case 'setBabies':
      if (action.babies > state.babies.length) {
        return {
          ...state,
          babies: [
            ...state.babies,
            ...Array(action.babies - state.babies.length).fill(0),
          ],
        };
      } else if (action.babies < state.babies.length) {
        return {
          ...state,
          babies: state.babies.filter((_, idx) => idx < action.babies),
        };
      } else {
        return state;
      }
    case 'changeBaby':
      return {
        ...state,
        babies: state.babies.map((item, idx) =>
          idx === action.idx ? action.value : item
        ),
      };
    case 'setName':
      return { ...state, name: action.name };
    case 'setPhone':
      return { ...state, phone: action.phone };
    case 'togglePolicy':
      return { ...state, policyIsChecked: !state.policyIsChecked };
    case 'openPolicy':
      return { ...state, policyIsOpen: true };
    case 'closePolicy':
      return { ...state, policyIsOpen: false };
    case 'unmount':
      return { ...state, isMounted: false };

    default:
      return state;
  }
};

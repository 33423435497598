import React from 'react';
import ReactDOM from 'react-dom';
import { Question, QuestionProvider } from '../components/question';

const initButton = () => {
  const button = document.querySelector('.contacts__button');

  button?.addEventListener('click', () => {
    ReactDOM.render(
      <React.StrictMode>
        <QuestionProvider>
          <Question />
        </QuestionProvider>
      </React.StrictMode>,
      document.getElementById('root')
    );
  });
};

const contacts = {
  init: () => {
    initButton();
  },
};

export default contacts;

import { createContext, Dispatch, FC, useReducer } from 'react';
import { addReviewReducer } from './add-review.reducer';
import { AddReviewState, AddReviewAction } from './add-review.types';

const initialState: AddReviewState = {
  grade: 5,
  text: '',
  name: '',
  email: '',
  policyIsChecked: false,
  modalIsOpen: true,
  thanksIsOpen: false,
  policyIsOpen: false,
  isMounted: true,
};

export const AddReviewContext = createContext<{
  state: AddReviewState;
  dispatch: Dispatch<AddReviewAction>;
}>({ state: initialState, dispatch: () => null });

export const AddReviewProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(addReviewReducer, initialState);

  return (
    <AddReviewContext.Provider value={{ state, dispatch }}>
      {children}
    </AddReviewContext.Provider>
  );
};

import { FC } from 'react';
import cn from 'classnames';
import { Checkbox } from '../checkbox';
import styles from './policy.module.scss';

export interface Props {
  isChecked: boolean;
  onChange: () => void;
  onClick: () => void;
  moduleClass?: string;
}

export const Policy: FC<Props> = ({
  isChecked,
  onChange,
  onClick,
  moduleClass,
}) => {
  const moduleStyles = cn(styles.module, moduleClass);

  return (
    <div className={moduleStyles}>
      <Checkbox
        moduleClass={styles.checkbox}
        checked={isChecked}
        onChange={onChange}
        required
      />
      <p className={styles.text}>
        Вы даете согласие на обработку{' '}
        <strong className={styles.button} onClick={onClick}>
          персональных данных
        </strong>
      </p>
    </div>
  );
};

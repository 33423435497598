export const getAdultOptions = () =>
  [...Array(10)].map((_, idx) => ({
    value: idx + 1,
    text: `${idx + 1}`,
  }));

export const getBabyOptions = () =>
  [...Array(11)].map((_, idx) => ({
    value: idx,
    text: `${idx}`,
  }));

import { FC, useEffect } from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import GLightbox from 'glightbox';
import cn from 'classnames';
import styles from './detail.module.scss';

SwiperCore.use([Navigation, Pagination]);

interface Props {
  name: string;
  images: string[];
  text: string;
  onClose: () => void;
  hasButton?: boolean;
  onButtonClick?: () => void;
}

export const Detail: FC<Props> = ({
  name,
  images,
  text,
  onClose,
  hasButton,
  onButtonClick,
}) => {
  useEffect(() => {
    const glightbox = GLightbox({
      selector: '.detail-slide-glighbox',
    });
    return () => glightbox.destroy();
  });

  const moduleStyles = cn(styles.module, {
    [styles.hasButton]: hasButton,
  });

  const slideStyles = cn(styles.slide, 'detail-slide-glighbox');

  return (
    <div className={moduleStyles}>
      <button className={styles.close} onClick={onClose} />
      <h4 className={styles.name}>{name}</h4>
      <div className={styles.content}>
        <div className={styles.slider}>
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            loop
            navigation={{
              prevEl: '.detail-swiper-nav-prev',
              nextEl: '.detail-swiper-nav-next',
            }}
            pagination={{
              el: '.detail-swiper-pagination',
              clickable: true,
            }}
          >
            {images.map((image, idx) => (
              <SwiperSlide key={idx}>
                <a className={slideStyles} href={image}>
                  <img src={image} alt="detail" />
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="detail-swiper-nav">
            <button className="detail-swiper-nav-prev" />
            <button className="detail-swiper-nav-next" />
          </div>
          <div className="detail-swiper-pagination" />
          <img className={styles.zoom} src="/images/magnifier.svg" />
        </div>
        <div className={styles.textual}>
          <div
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: text }}
          />
          {hasButton && (
            <button className={styles.button} onClick={onButtonClick}>
              Заказать услугу
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

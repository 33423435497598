import { FC, useContext } from 'react';
import { Modal } from '../_common/modal';
import { Textual } from '../_common/textual';
import { Thanks } from '../_common/thanks';
import { CalculationQuizBody } from './calculation-quiz-body';
import { CalculationQuizContext } from './calculation-quiz.context';

export const CalculationQuiz: FC = () => {
  const { state, dispatch } = useContext(CalculationQuizContext);

  return (
    <>
      <CalculationQuizBody />
      <Modal
        isOpen={state.thanksIsOpen}
        onOverlayClick={() => dispatch({ type: 'closeThanks' })}
      >
        <Thanks onClose={() => dispatch({ type: 'closeThanks' })} />
      </Modal>
      <Modal
        isOpen={state.policyIsOpen}
        onOverlayClick={() => dispatch({ type: 'closePolicy' })}
      >
        <Textual
          type="policy"
          onClose={() => dispatch({ type: 'closePolicy' })}
        />
      </Modal>
    </>
  );
};

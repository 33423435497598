import { createContext, Dispatch, FC, useReducer } from 'react';
import { serviceCalculationReducer } from './service-calculation.reducer';
import {
  ServiceCalculationState,
  ServiceCalculationAction,
} from './service-calculation.types';

const initialState: ServiceCalculationState = {
  date: undefined,
  time: '',
  name: '',
  phone: '',
  policyIsChecked: false,
  modalIsOpen: true,
  dateIsOpen: false,
  thanksIsOpen: false,
  policyIsOpen: false,
  isMounted: true,
};

export const ServiceCalculationContext = createContext<{
  state: ServiceCalculationState;
  dispatch: Dispatch<ServiceCalculationAction>;
}>({ state: initialState, dispatch: () => null });

export const ServiceCalculationProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(serviceCalculationReducer, initialState);

  return (
    <ServiceCalculationContext.Provider value={{ state, dispatch }}>
      {children}
    </ServiceCalculationContext.Provider>
  );
};

import React from 'react';
import ReactDOM from 'react-dom';
import { Booking } from '../components/booking';
import { BookingProvider } from '../components/booking/booking.context';

const initCalculation = () => {
  const button = document.querySelector('.screen__calculation');

  button?.addEventListener('click', () =>
    ReactDOM.render(
      <React.StrictMode>
        <BookingProvider>
          <Booking kind="calculation" />
        </BookingProvider>
      </React.StrictMode>,
      document.getElementById('root')
    )
  );
};

const initBooking = () => {
  const button = document.querySelector('.screen__booking');

  button?.addEventListener('click', () =>
    ReactDOM.render(
      <React.StrictMode>
        <BookingProvider>
          <Booking kind="booking" />
        </BookingProvider>
      </React.StrictMode>,
      document.getElementById('root')
    )
  );
};

const screen = {
  init: () => {
    initCalculation();
    initBooking();
  },
};

export default screen;

import React, { FC, useState } from 'react';
import ReactDOM from 'react-dom';
import { ServiceCalculation } from '../service-calculation';
import { ServiceCalculationProvider } from '../service-calculation/service-calculation.context';
import { Detail } from '../_common/detail';
import { Modal } from '../_common/modal';

interface Props {
  service: string;
  workTime: string[];
  images: string[];
  text: string;
}

export const Service: FC<Props> = ({ service, workTime, images, text }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleButtonClick = () =>
    ReactDOM.render(
      <React.StrictMode>
        <ServiceCalculationProvider>
          <ServiceCalculation service={service} workTime={workTime} />
        </ServiceCalculationProvider>
      </React.StrictMode>,
      document.getElementById('service-root')
    );

  return (
    <Modal
      isOpen={isOpen}
      onOverlayClick={() => setIsOpen(false)}
      onFinishAnimation={() =>
        ReactDOM.unmountComponentAtNode(document.getElementById('root')!)
      }
    >
      <Detail
        name={service}
        images={images}
        text={text}
        onClose={() => setIsOpen(false)}
        hasButton
        onButtonClick={handleButtonClick}
      />
    </Modal>
  );
};

import { createContext, Dispatch, FC, useReducer } from 'react';
import { bookingReducer } from './booking.reducer';
import { BookingState, BookingAction } from './booking.types';

const initialState: BookingState = {
  arrival: undefined,
  departure: undefined,
  adults: 2,
  babies: [],
  name: '',
  phone: '',
  policyIsChecked: false,
  modalIsOpen: true,
  arrivalIsOpen: false,
  departureIsOpen: false,
  babiesIsOpen: false,
  thanksIsOpen: false,
  policyIsOpen: false,
  isMounted: true,
};

export const BookingContext = createContext<{
  state: BookingState;
  dispatch: Dispatch<BookingAction>;
}>({ state: initialState, dispatch: () => null });

export const BookingProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(bookingReducer, initialState);

  return (
    <BookingContext.Provider value={{ state, dispatch }}>
      {children}
    </BookingContext.Provider>
  );
};

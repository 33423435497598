import { FC, MouseEvent, useContext } from 'react';
import { CalculationQuizContext } from '../calculation-quiz.context';
import styles from './calculation-quiz-control.module.scss';

export const CalculationQuizControl: FC = () => {
  const { state, dispatch } = useContext(CalculationQuizContext);

  const { step, arrival, departure } = state;

  const handleNext = (event: MouseEvent<HTMLButtonElement>) => {
    if (step === 1 && !arrival) {
      setTimeout(() => {
        dispatch({ type: 'openArrival' });
      });
      event.preventDefault();
      return;
    }
    if (step === 1 && !departure) {
      setTimeout(() => {
        dispatch({ type: 'openDeparture' });
      });
      event.preventDefault();
      return;
    }

    dispatch({ type: 'nextStep' });
  };

  return (
    <div className={styles.module}>
      {step === 2 && (
        <button
          className={styles.prev}
          type="button"
          onClick={() => dispatch({ type: 'prevStep' })}
        >
          Назад
        </button>
      )}
      {step < 3 && (
        <button className={styles.next} type="button" onClick={handleNext}>
          Далее
        </button>
      )}
      {step === 3 && (
        <button className={styles.confirm}>Получить пасчет</button>
      )}
    </div>
  );
};

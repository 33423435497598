import { FC, TextareaHTMLAttributes } from 'react';
import cn from 'classnames';
import styles from './textarea.module.scss';

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  moduleClass?: string;
}

export const Textarea: FC<Props> = ({ moduleClass, ...rest }) => {
  const moduleStyles = cn(styles.module, moduleClass);

  return <textarea className={moduleStyles} {...rest} />;
};

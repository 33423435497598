import { FC } from 'react';
import styles from './thanks.module.scss';

interface Props {
  onClose: () => void;
  isAddReview?: boolean;
}

export const Thanks: FC<Props> = ({ onClose, isAddReview }) => {
  return (
    <div className={styles.module}>
      <button className={styles.close} onClick={() => onClose()} />
      <img className={styles.icon} src="/images/thanks-icon.svg" alt="thanks" />
      <strong className={styles.title}>
        {isAddReview
          ? 'Спасибо, Ваш отзыв отправлен!'
          : 'Спасибо, заявка отправлена!'}
      </strong>
      <p className={styles.text}>
        {isAddReview
          ? 'В ближайшее время он будет добавлен на сайт!'
          : 'Мы свяжемся с вами в ближайшее время!'}
      </p>
      <button className={styles.button} onClick={() => onClose()}>
        Ок
      </button>
    </div>
  );
};

import { FC, useEffect, useRef } from 'react';
import styles from './textual.module.scss';

const policyText = document.getElementById('policy-text');
const rulesText = document.getElementById('rules-text');
const cookieText = document.getElementById('cookie-text');

const titleText = {
  policy: 'Политика конфиденциальности',
  rules: 'Пользовательское соглашение',
  cookie: 'Политика использования cookie',
};

interface Props {
  type: 'policy' | 'rules' | 'cookie';
  onClose: () => void;
}

export const Textual: FC<Props> = ({ type, onClose }) => {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!contentRef.current) {
      return;
    }
    if (type === 'policy' && policyText) {
      contentRef.current.innerHTML = policyText.innerHTML;
    }
    if (type === 'rules' && rulesText) {
      contentRef.current.innerHTML = rulesText.innerHTML;
    }
    if (type === 'cookie' && cookieText) {
      contentRef.current.innerHTML = cookieText.innerHTML;
    }
  }, [type]);

  return (
    <div className={styles.module}>
      <button className={styles.close} onClick={() => onClose()} />
      <h3 className={styles.title}>{titleText[type]}</h3>
      <div className={styles.content} ref={contentRef}></div>
    </div>
  );
};

import React from 'react';
import ReactDOM from 'react-dom';
import { Cookie } from '../components/cookie';
import { TextualModal } from '../components/textual-modal';

const initPolicy = () => {
  const button = document.querySelector('.footer__policy');

  button?.addEventListener('click', () => {
    ReactDOM.render(
      <React.StrictMode>
        <TextualModal type="policy" />
      </React.StrictMode>,
      document.getElementById('root')
    );
  });
};

const initRules = () => {
  const button = document.querySelector('.footer__rules');

  button?.addEventListener('click', () => {
    ReactDOM.render(
      <React.StrictMode>
        <TextualModal type="rules" />
      </React.StrictMode>,
      document.getElementById('root')
    );
  });
};

const initCookie = () => {
  const button = document.querySelector('.footer__cookie');

  button?.addEventListener('click', () => {
    ReactDOM.render(
      <React.StrictMode>
        <TextualModal type="cookie" />
      </React.StrictMode>,
      document.getElementById('root')
    );
  });
};

const initCookieConfirmation = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Cookie />
    </React.StrictMode>,
    document.getElementById('cookie')
  );
};

const footer = {
  init: () => {
    initPolicy();
    initRules();
    initCookie();
    initCookieConfirmation();
  },
};

export default footer;

import { FC, useState } from 'react';
import ReactDOM from 'react-dom';
import { Modal } from '../_common/modal';
import { ReviewBody } from './review-body';

interface Props {
  name: string;
  grade: string;
  text: string;
}

export const Review: FC<Props> = ({ name, grade, text }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onOverlayClick={() => setIsOpen(false)}
        onFinishAnimation={() =>
          ReactDOM.unmountComponentAtNode(document.getElementById('root')!)
        }
      >
        <ReviewBody
          name={name}
          grade={grade}
          text={text}
          onClose={() => setIsOpen(false)}
        />
      </Modal>
    </>
  );
};

import { FC, InputHTMLAttributes } from 'react';
import cn from 'classnames';
import styles from './input.module.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  moduleClass?: string;
}

export const Input: FC<Props> = ({ name, moduleClass, ...rest }) => {
  const moduleStyles = cn(styles.module, moduleClass);

  return (
    <label className={moduleStyles}>
      <span className={styles.name}>{name}</span>
      <input className={styles.input} {...rest} />
    </label>
  );
};

import { FC, useState } from 'react';
import ReactDOM from 'react-dom';
import { Modal } from '../_common/modal';
import { Textual } from '../_common/textual';

interface Props {
  type: 'policy' | 'rules' | 'cookie';
}

export const TextualModal: FC<Props> = ({ type }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Modal
      isOpen={isOpen}
      onOverlayClick={() => setIsOpen(false)}
      onFinishAnimation={() =>
        ReactDOM.unmountComponentAtNode(document.getElementById('root')!)
      }
    >
      <Textual type={type} onClose={() => setIsOpen(false)} />
    </Modal>
  );
};

import { FC, InputHTMLAttributes } from 'react';
import cn from 'classnames';
import styles from './checkbox.module.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  moduleClass?: string;
}

export const Checkbox: FC<Props> = ({ moduleClass, ...rest }) => {
  const moduleStyles = cn(styles.module, moduleClass);

  return (
    <label className={moduleStyles}>
      <input className={styles.checkbox} type="checkbox" {...rest} />
      <span className={styles.checkMark} />
    </label>
  );
};

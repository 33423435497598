import { FC } from 'react';
import styles from './cookie-body.module.scss';

interface Props {
  onClose: () => void;
  onPolicyClick: () => void;
  onConfirm: () => void;
}

export const CookieBody: FC<Props> = ({
  onClose,
  onPolicyClick,
  onConfirm,
}) => {
  return (
    <div className={styles.module}>
      <button className={styles.close} onClick={onClose} />
      <p className={styles.text}>
        <span>
          Наш сайт использует файлы Cookie. Продолжая пользоваться сайтом, вы
          соглашаетесь на использование нами ваших файлов Cookie
        </span>{' '}
        <button className={styles.detail} onClick={onPolicyClick}>
          Подронее
        </button>
      </p>
      <button className={styles.confirm} onClick={onConfirm}>
        Хорошо, спасибо
      </button>
    </div>
  );
};

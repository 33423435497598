import React from 'react';
import ReactDOM from 'react-dom';

import Swiper, { Navigation, Pagination } from 'swiper';
import { Advantage } from '../components/advantage';

Swiper.use([Navigation, Pagination]);

const initSlider = () => {
  new Swiper('.advantages__slider .swiper-container', {
    loop: true,
    spaceBetween: 10,
    loopedSlides: 10,

    navigation: {
      nextEl: '.advantages__next',
      prevEl: '.advantages__prev',
    },

    pagination: {
      el: '.advantages__pagination',
      clickable: true,
    },

    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 15,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    },
  });
};

const initDetail = () => {
  const items =
    document.querySelectorAll<HTMLButtonElement>('.advantages__slide');
  items.forEach((item) =>
    item.addEventListener('click', () =>
      ReactDOM.render(
        <React.StrictMode>
          <Advantage
            name={item.dataset.name!}
            images={item.dataset.images!.split(',')}
            text={item.dataset.text!}
          />
        </React.StrictMode>,
        document.getElementById('root')
      )
    )
  );
};

const advantages = {
  init: () => {
    initSlider();
    initDetail();
  },
};

export default advantages;

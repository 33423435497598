import './styles/main.scss';

import header from './scripts/header';
import screen from './scripts/screen';
import about from './scripts/about';
import advantages from './scripts/advantages';
import location from './scripts/location';
import services from './scripts/services';
import rooms from './scripts/rooms';
import question from './scripts/question';
import reviews from './scripts/reviews';
import calculation from './scripts/calculation';
import contacts from './scripts/contacts';
import footer from './scripts/footer';

header.init();
screen.init();
about.init();
advantages.init();
location.init();
services.init();
rooms.init();
question.init();
reviews.init();
calculation.init();
contacts.init();
footer.init();

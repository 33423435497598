import { FC, useState } from 'react';
import ReactDOM from 'react-dom';
import { Detail } from '../_common/detail';
import { Modal } from '../_common/modal';

interface Props {
  name: string;
  images: string[];
  text: string;
}

export const Advantage: FC<Props> = ({ name, images, text }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Modal
      isOpen={isOpen}
      onOverlayClick={() => setIsOpen(false)}
      onFinishAnimation={() =>
        ReactDOM.unmountComponentAtNode(document.getElementById('root')!)
      }
    >
      <Detail
        name={name}
        images={images}
        text={text}
        onClose={() => setIsOpen(false)}
      />
    </Modal>
  );
};

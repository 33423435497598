import { FC, FormEvent, MouseEvent, useContext, useRef } from 'react';
import { Calendar } from '../../_common/calendar';
import { Babies } from '../../_common/babies';
import { DatePicker } from '../../_common/date-picker';
import { Input } from '../../_common/input';
import { Policy } from '../../_common/policy';
import { Select } from '../../_common/select';
import { BookingContext } from '../booking.context';
import styles from './booking-body.module.scss';
import { BabiesDropdown } from '../../_common/babies-dropdown';
import { getAdultOptions, getBabyOptions } from '../../../utils/utils';

declare const ym: any;
declare const gtag: any;
declare const _tmr: any;

interface Props {
  kind: 'booking' | 'calculation';
  onClose: (isNeedThanks?: boolean) => void;
  room?: string;
}

export const BookingBody: FC<Props> = ({ kind, onClose, room }) => {
  const { state, dispatch } = useContext(BookingContext);
  const { arrival, departure, adults, babies, name, phone } = state;

  const arrivalRef = useRef<HTMLDivElement>(null);
  const departureRef = useRef<HTMLDivElement>(null);
  const babiesRef = useRef<HTMLDivElement>(null);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    ym(58297606, 'reachGoal', 'kick');
    gtag('event', 'submit', {
      event_category: 'form',
    });

    _tmr.push({ id: '3246592', type: 'reachGoal', goal: 'arrows' });

    onClose(true);

    const clientId = document.body.dataset.clientId;
    const source = document.body.dataset.source;

    const utms = await fetch('/ajax/_forms.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        form: kind,
        room,
        arrival: arrival?.toLocaleDateString(),
        departure: departure?.toLocaleDateString(),
        adults,
        babies,
        name,
        phone,
        clientId,
        source,
      }),
    }).then(() => {
      dispatch({ type: 'closeModal' });
      dispatch({ type: 'openThanks' });
    });
  };

  const handleConfirm = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (!arrival) {
      dispatch({ type: 'openArrival' });
      event.preventDefault();
      return;
    }
    if (!departure) {
      dispatch({ type: 'openDeparture' });
      event.preventDefault();
      return;
    }
  };

  return (
    <>
      <div className={styles.module}>
        <button className={styles.close} onClick={() => onClose()} />
        <h3 className={styles.title}>
          {kind === 'booking' ? 'Бронирование' : 'Расчет стоимости'}
        </h3>
        <form onSubmit={handleSubmit}>
          <DatePicker
            moduleClass={styles.arrival}
            name="Дата заезда:"
            value={arrival}
            onClick={() => dispatch({ type: 'toggleArrival' })}
            ref={arrivalRef}
          />
          <DatePicker
            moduleClass={styles.departure}
            name="Дата отъезда:"
            value={departure}
            onClick={() => dispatch({ type: 'toggleDeparture' })}
            ref={departureRef}
          />
          <Select
            moduleClass={styles.adults}
            options={getAdultOptions()}
            paddingLeft={170}
            name="Кол-во взрослых:"
            value={adults}
            onChange={(event) =>
              dispatch({ type: 'setAdults', adults: +event.target.value })
            }
          />
          <Select
            moduleClass={styles.babiesSelect}
            options={getBabyOptions()}
            paddingLeft={142}
            name="Кол-во детей:"
            value={babies.length}
            onChange={(event) =>
              dispatch({ type: 'setBabies', babies: +event.target.value })
            }
          />
          {babies.length > 0 && (
            <Babies
              moduleClass={styles.babies}
              babies={babies}
              onClick={() => dispatch({ type: 'toggleBabies' })}
              ref={babiesRef}
            />
          )}
          <Input
            moduleClass={styles.name}
            name="Имя:"
            size={10}
            value={name}
            onChange={(event) =>
              dispatch({ type: 'setName', name: event.target.value })
            }
          />
          <Input
            moduleClass={styles.phone}
            name="Тел:"
            size={10}
            type="tel"
            pattern="^\+?[0-9()\- ]+"
            required
            value={phone}
            onChange={(event) =>
              dispatch({ type: 'setPhone', phone: event.target.value })
            }
          />
          <Policy
            moduleClass={styles.policy}
            isChecked={state.policyIsChecked}
            onChange={() => dispatch({ type: 'togglePolicy' })}
            onClick={() => dispatch({ type: 'openPolicy' })}
          />
          <button className={styles.confirm} onClick={handleConfirm}>
            {kind === 'booking' ? 'Забронировать' : 'Получить расчет'}
          </button>
        </form>
      </div>
      <Calendar
        isOpen={state.arrivalIsOpen}
        datePickerRef={arrivalRef}
        disabledDaysBefore={new Date()}
        onDateClick={(date) => dispatch({ type: 'setArrival', arrival: date })}
        onClose={() => dispatch({ type: 'closeArrival' })}
        inModal
      />
      <Calendar
        isOpen={state.departureIsOpen}
        datePickerRef={departureRef}
        disabledDaysBefore={arrival || new Date()}
        onDateClick={(date) =>
          dispatch({ type: 'setDeparture', departure: date })
        }
        onClose={() => dispatch({ type: 'closeDeparture' })}
        inModal
      />
      <BabiesDropdown
        isOpen={state.babiesIsOpen}
        babiesRef={babiesRef}
        babies={babies}
        onChangeBaby={(value, idx) =>
          dispatch({ type: 'changeBaby', value, idx })
        }
        onClose={() => dispatch({ type: 'closeBabies' })}
        inModal
      />
    </>
  );
};

import { AddReviewState, AddReviewAction } from './add-review.types';

export const addReviewReducer = (
  state: AddReviewState,
  action: AddReviewAction
): AddReviewState => {
  switch (action.type) {
    case 'closeModal':
      return { ...state, modalIsOpen: false };
    case 'openThanks':
      return { ...state, thanksIsOpen: true };
    case 'closeThanks':
      return { ...state, thanksIsOpen: false };
    case 'setGrade':
      return { ...state, grade: action.grade };
    case 'setText':
      return { ...state, text: action.text };
    case 'setName':
      return { ...state, name: action.name };
    case 'setEmail':
      return { ...state, email: action.email };
    case 'togglePolicy':
      return { ...state, policyIsChecked: !state.policyIsChecked };
    case 'openPolicy':
      return { ...state, policyIsOpen: true };
    case 'closePolicy':
      return { ...state, policyIsOpen: false };
    case 'unmount':
      return { ...state, isMounted: false };

    default:
      return state;
  }
};

import { forwardRef } from 'react';
import cn from 'classnames';
import styles from './babies.module.scss';

interface Props {
  babies: number[];
  onClick: () => void;
  moduleClass?: string;
}

export const Babies = forwardRef<HTMLDivElement, Props>(
  ({ babies, onClick, moduleClass }, ref) => {
    const moduleStyles = cn(styles.module, moduleClass);

    return (
      <div className={moduleStyles} ref={ref} onClick={onClick}>
        <div className={styles.name}>Возраст детей</div>
        <div className={styles.values}>
          {babies.filter((_, idx) => idx < 4).join(', ')}
          {babies.length > 4 && ', ...'}
        </div>
      </div>
    );
  }
);
